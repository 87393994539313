
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: "AuthPasswordMain",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    submit() {
      this.API.auth.onLoginPassword({ email: this.email });
    },
  },
});
